







import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import ManualForm from '@/components/forms/depository/ManualForm.vue'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    ManualForm,
  },
})
export default class DepositoryManualNew extends Mixins(NotifyMixin) {
  private get parentID () {
    return this.$route.query.parentID || null
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Новая статья',
    }
  }
}
